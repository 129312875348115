import React, { useState } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Layout from "../components/layout"
import Image from "../components/image"
import EmailSignupForm from "../components/email_signup_form"
import SEO from "../components/seo"

// const stgMusicLinks = {
//     spotify: 'https://open.spotify.com/album/0IQ29MXnQrTl8BqWM88AFM',
//     youtube: 'https://www.youtube.com/watch?v=4IvvpPbI7T0',
//     appleMusic: 'https://music.apple.com/us/album/supposed-to-go-single/1486119304?uo=4&app=itunes&at=1001lry3&ct=dashboard',
//     googlePlay: 'https://play.google.com/store/music/album/Andrew_Plan_People_Needing_People_feat_Kristine_Wr?id=Bddp3dsqblgjurxapckh3u3u4rq'
// };

const pnpMusicLinks = {
    spotify: 'https://open.spotify.com/album/7jyMMyEYeop00oVCj8sYOF',
    youtube: 'https://music.youtube.com/playlist?list=OLAK5uy_lTcq3D1IHKiMhyJd1RA1iPgKMRh4M7bPM&src=Linkfire&lId=8883fa35-8435-48a5-81ee-4e992d9c5f71&cId=d3d58fd7-4c47-11e6-9fd0-066c3e7a8751',
    iTunes: 'https://itunes.apple.com/us/album/1524981912?uo=4&app=itunes&at=1l3vpUI&lId=22177529&cId=none&sr=3&src=Linkfire&itscg=30440&itsct=catchall_p3&ct=LFV_f1e5270c8552c4f45584ca82daf843a4&ls=1',
    appleMusic: 'https://music.apple.com/us/album/1524981912?uo=4&app=music&at=1l3vpUI&lId=22177529&cId=none&sr=1&src=Linkfire&itscg=30440&itsct=catchall_p1&ct=LFV_f1e5270c8552c4f45584ca82daf843a4&ls=1',
    googlePlay: 'https://play.google.com/store/music/album/Andrew_Plan_People_Needing_People_feat_Kristine_Wr?id=Bddp3dsqblgjurxapckh3u3u4rq&PCamRefID=LFV_f1e5270c8552c4f45584ca82daf843a4',
};

const headingStyle = {
  marginTop: `1em`,
  fontWeight: `400`,
  textAlign: `center`,
  fontFamily: 'Ropa Sans, sans-serif',
  whiteSpace: `nowrap`,
  // fontSize: '1.2em',
};

const singleLinksStyle = {
  margin: `0 auto`,
  paddingBottom: `1em`,
  display: `flex`,
  justifyContent: `space-evenly`,
  minWidth: 200,
  maxWidth: 300
};

const iconStyle = {
  color: `#313639`,
}

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div id="index-container" style={{ 
      maxWidth: `960px`
      , marginBottom: `1.45rem`
      , margin: `0 auto`
      , display: `block` 
      , fontFamily: 'Ropa Sans, sans-serif'
      , textAlign: `center`
    }}>
      <div
        id="top-container"
        css={{
          '@media (min-width: 770px)': {
            display: `flex`,
            justifyContent: `space-evenly`
          }
        }}
      >
        <div 
          id="headshot-container"
          css={{ 
            margin: `0 5em 1em 5em`,
            flexGrow: `4`,
            '@media (min-width: 770px)': {
              margin: `0`,
            },
          }}
        >
          <Image />
        </div>
        <div
          id="impt-container"
          css={{
            flexGrow: `1`,
            display: `flex`,
            flexDirection: `column`,
            justifyContent: `center`
          }}
        >
          <h3 css={ headingStyle }>
            LISTEN TO MY NEW SINGLE
            <br />
            <b>PEOPLE NEEDING PEOPLE</b>
            <br />
            FEAT. KRISTINE WRIDING
          </h3>
          
          <div style={ singleLinksStyle }>
            <a href={ pnpMusicLinks.spotify } >
              <FontAwesomeIcon icon={["fab", "spotify"]} style={iconStyle} size="1x" />
            </a>
            <a href={ pnpMusicLinks.youtube } >
              <FontAwesomeIcon icon={["fab", "youtube"]} style={iconStyle} size="1x" />
            </a>
            <a href={ pnpMusicLinks.appleMusic } >
              <FontAwesomeIcon icon={["fab", "apple"]} style={iconStyle} size="1x" />
            </a>
            <a href={ pnpMusicLinks.googlePlay } >
              <FontAwesomeIcon icon={["fab", "google-play"]} style={iconStyle} size="1x" />
            </a>
            <a href={ pnpMusicLinks.iTunes } >
              <FontAwesomeIcon icon={["fab", "itunes-note"]} style={iconStyle} size="1x" />
            </a>
            {/* <a href={ pnpMusicLinks.amazon } >
              <FontAwesomeIcon icon={["fab", "amazon"]} style={iconStyle} size="1x" />
            </a> */}
          </div>

          {/* <div id="mp3-container" style={{
            margin: `0 auto`
            , display: `flex`
            , justifyContent: `center`
            , alignItems: `center`
          }}>
            <audio 
              src={ stg } type="audio/mpeg" controls="controls"
              controlsList="nodownload"
            >
              Your browser does not support the &lt;audio&gt; tag.
            </audio>
          </div> */}

          <div style={{ marginTop: `2.2em` }}>
            <div style={{
              margin: `0 auto`
              , display: `flex`
              , justifyContent: `center`
            }}>
              <EmailSignupForm />
            </div>
          </div>
        </div>
      </div>
      <hr 
        css={{ 
          '@media (min-width: 420px)': {
            marginTop: `2.2em` 
          },
        }}
      />
      <div style={{ marginTop: `2.2em` }}>
        <p style={{ margin: `0`, textAlign: `center` }}>INQUIRIES:</p>
        <a href="mailto:andrew@andrewplan.com">
          <p style={{ margin: `0`, textAlign: `center` }}>andrew@andrewplan.com</p>
        </a>
      </div>
    </div>
  </Layout>
)

export default IndexPage
